<script setup>
  import { storeToRefs } from 'pinia'
  import { useToolStore } from '@/stores/toolStore'
  import { onBeforeUnmount, onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'

  const store = useToolStore()
  const {isMenuOpen} =storeToRefs(store)
  const router = useRouter()  

  const mobileMenu = ref(null);
  let clicknum = ref(0);

  const handleClickOutside = (event) => {
          if(isMenuOpen.value){
            if (!mobileMenu.value.contains(event.target) && clicknum.value > 0) {
              closeMenu();
              clicknum.value=0;
            } else{
            ++clicknum.value;
            }
          }
        }

  const closeMenu = () => {
          isMenuOpen.value = false;
        } 

  const navToCODB= () => {
          window.open('https://docs.google.com/spreadsheets/d/11PuqDKQHbvN7kym0AVXT5w7r8JNnZ7YQF_42SvAu-C0/template/preview', '_blank', 'noopener,noreferrer');
        }

  const navToPhotogs = () => {
          store.handleNavToPhotogs(router, 'mobile')
        }
  onMounted(()=>{
    document.addEventListener('click', handleClickOutside);
  });
  
  onBeforeUnmount(()=>{
    document.removeEventListener('click', handleClickOutside);
  });
</script>

<template>
    <div class="mobile-menu" ref="mobileMenu">
      <a class="menu-item" @click="navToPhotogs" style="color: #484A4B; cursor: pointer;">SEARCH FOR PHOTOGRAPHERS</a>
      <router-link to="/mapTool" class="menu-item" @click="closeMenu">LOCATIONS MAP</router-link>
      <a class="menu-item" @click="navToCODB" style="color: #484A4B; cursor: pointer;">COST OF DOING BUSINESS</a>
      <a href="https://erinthompsonphoto.com/education" class="menu-item">RETURN TO ETP</a>
    </div>
</template>
    
<style scoped>
  .mobile-menu {
    width: 100%;
    background-color: #BBDEF0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 8vh;
    right: 0;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    font-family: 'Montserrat', sans-serif;
    opacity: 1;
    visibility: visible;
    padding-top:10px
  }
  
  .mobile-menu.hidden {
    opacity: 0;
    visibility: hidden;
  }
  
  .menu-item {
    color: #484A4B;
    text-decoration: none;
    margin: 10px 0;
    font-size: 1.1rem;
    transition: color 0.3s;
    padding-bottom:10px;
  }
  
  .menu-item:hover {
    color: #0081A7;
  }
</style>
  