<script setup>

</script>

<template>
  <div class="scrolling">
  <div class="container">
    <div class="header">
      <h1 class="normal">An even better map is coming!</h1>
    </div>
    <div class="container2">
      <div class="box">
        <p class="body">
          The new map will live here, but in the meantime, check out all the bases we've covered on the current version.
        </p>    
      </div>
      <a href="https://erinthompsonphoto.com/education" class="button">VIEW THE MAP
      </a>
    </div>
  </div>
  </div>
</template>

  
<style scoped>
    .scrolling{
      display:flex;
      justify-content: center;
    }
    .container{
      display:flex;
      flex-direction: column;
      margin-top:250px;
      max-width: 1100px;
      padding:0px 20px;
      justify-self: center;
    }
    .container2{
      display:flex;
      flex-direction: column;
      align-items: center;
    }
    .Left{
      height:100%;
      width: 175%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .box{
      background-color: rgba(255,255,255,.5);
      height:160px;
      width: 95%;
      max-width: 747px;      
    }
    h1 {
      font-size: 50px;
      font-family: 'amaranth';
      color: #0081a7;
      font-weight: normal;
      top:-70px;
      z-index: 4;

    }
    .header{
      display: flex;
      height:0px;
      justify-content: center;
    }
    .italics {
      font-style: italic;
      margin-left:15px;
    }
    .body{
      font-family: 'montserrat';
      color: #484A4B;
      font-size:20px;
      padding:35px;
    }
    .highlight{
      color:#f4899c;
      font-weight: bold;
    }
    a {
      display:flex;
      justify-content: center;
      margin:-20px 5px;
    }
    a.button{
      background-color:#BBDEF0;
      color:#0081a7;
      font-family:'amaranth';
      font-size:18px;
      top:-20px;
      border:none;
      margin:0px 10px;
      padding: 10px 35px;
      width: 240px;
      text-decoration: none;
    }
    a .button:hover{
      cursor:pointer;
      background-color:#afd8ec
    }
    .erin{
      top: 2px;
      height: 315px ;
      padding:7.5px;
      background-color: #fff;
    }
    .Right p{
      color:#f4899c;
      font:15px;
      top:-40px;
    }

    @media (max-width: 1000px){
      h1{
        font-size: 35px;
        top:-40px;
      }
      .body{
        font-size: 18px;
      }
      .box{
        width: 594px;
      }
    }
    @media (max-width: 840px) {
      .scrolling{
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
      .container{
        display:flex;
        flex-direction: column;
        padding:0px 5px;
        min-height: 100vh;
      }
      .header{
        top:-100px;
      }
      .header h1{
        font-size:30px;
      }
      .container2{
        flex-direction: column;
      }
      .box{
        top:-100px;
        height:200px;
        width: 95%;
        background-color: rgba(255,255,255,.7);
      }
      .body{
        font-size: 20px;
        top:-20px;
      }
      a.button{
        top:-120px
      }
    }
</style>